import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'

const PrivacyPolicy = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (      
          <Link to={closeTo} className="modalcloselink">X</Link>
        ) : (
          <header>
&nbsp;
          </header>
        )}

        <h2>PRIVACY POLICY</h2>       

        <p>The Privacy Policy set forth below are applicable to all Jukin Media, Inc. ("Jukin") owned or
        operated brands and websites, including but not limited to, the web sites located at
        www.JukinMedia.com www.thepetcollective.tv, www.failarmy.com, www.jukinvideo.com,
        www.peopleareawesome.com, www.dailypicksandflicks.com, www.thisishappeningshow.com,
        www.pokemyheart.tv, and each of their respective affiliates, parents, subsidiaries, licensees, and
        assigns (collectively hereinafter referred to, alternatively, as “Jukin Media,” the “Site,” “we,”
        “us,” “our” or similar identifying pronouns). Jukin Media strives to provide the best service
        possible to its users. When you share information with us about yourself (including without
        limitation by becoming a member or simply by using the Site), we collect this information and
        may use it in different ways with the goal of developing, improving, protecting and providing
        even better services.</p>

        <h3>HOW INFORMATION IS COLLECTED</h3>

        <p>By using the Site you are agreeing to our Terms of Service and Privacy Policy. As part of our
        service we collect certain information in order to learn from our visitors and improve our
        services. Information may be provided directly by you or through your usage of services and the
        Site, including third party sites and advertising linked or accessed from our Site. Such
        information may be gathered in a number of ways and from many sources, including without
        limitation, from a membership account, sign-in, IP address, service usage and views to specific
        content, visits to linked third party sites and/or advertising, device-specific information (e.g.
        operating system, mobile network, related device identifiers, system activity, settings and the
        like), browser web storage and similar application data caches, cookies and other similar
        identifiers.</p>

        <h3>INFORMATION JUKIN MEDIA MAY SHARE</h3>

        <p>In order to provide a premiere level of service, Jukin Media works with various third party
        vendors, partners, advertisers and other service providers in different industries and types of
        business. Except as otherwise prohibited by Internet privacy legislation, Jukin Media reserves the
        right to share information it collects from the Site to its trusted partners. Jukin Media may also
        share information if we have a good-faith belief that access, use, preservation or disclosure of the
        information is reasonably necessary (i) to enforce the Terms of Service; (ii) to investigate,
        prevent or take action in connection with potential violations of the Terms of Service or any
        potentially fraudulent, threatening or illegal activities; (iii) to investigate, prevent or take action
        in connection with potential technical issues; (iv) to respond to claims that any such information
        violates the rights of others; (v) to protect the rights, property or personal safety of Jukin Media,
        its affiliates, parent, subsidiaries, suppliers and their respective directors, officers, employees and
        agents and/or users of or visitors to the Site, and the public; (vi) to respond to court orders,
        subpoenas or otherwise exercise Jukin Media’s legal rights and/or defend against legal claims;
        and (vii) as otherwise required by any applicable law, regulation, or enforceable judicial,
        administrative or governmental request.</p>

        <h3>DISCLAIMER OF LIABILITY; USER OBLIGATION TO PROTECT INFORMATION</h3>

        <p> Jukin Media is not responsible or liable for information it obtains about its users through the Site or which it shares pursuant to the terms of this Privacy Policy and its Terms of Service.
        Notwithstanding the foregoing, we do regularly review our compliance with our Privacy Policy
        and aim to protect our Site and our users from unauthorized access to or unauthorized alteration,
        copying, disclosure or destruction of information we hold. Some of the measures we may take
        include the periodic review of our information collection, storage and processing practices
        (including physical security measures) to guard against unauthorized access to systems and
        restricting access to personal information to those employees, contractors, partners and agents
        who need to know that information in order to process it for us, and who are subject to
        contractual confidentiality obligations and may be disciplined or terminated if they fail to meet
        these obligations. While we reserve the right to take appropriate confidentiality and security
        measures, you should not have any expectation of privacy when using the Site or the Internet
        generally. Internet users may best protect their privacy through controlled disclosure of personal
        information. In order to keep their information private, users need to be careful what they submit,
        provide, disclose, review and look at online, including in connection with the Site. Should you
        wish to enhance your privacy, you may wish to explore higher security settings on your
        computer. You may also decide to set your browser to block cookies; however, please
        understand that many of our services may not function properly if your cookies are disabled. If
        you are a parent, you may elect to use parental control settings on your device to prevent, limit or
        control access to the site.</p>

        <h3>EUROPEAN UNION CITIZENS OR RESIDENTS</h3>

        <p> Please click <a href="https://www.jukinvideo.com/terms-eu">HERE</a> for additional privacy policies that may apply to you.</p>

        <h3>CHANGES TO PRIVACY POLICY</h3>
        <p>By using the Site you are agreeing to our Terms of Service and to the terms of our Privacy Policy
        as set forth herein. We reserve the right to make changes to our Terms of Service and our
        Privacy Policy at any time. It is your responsibility to periodically review the Terms of Service
        and Privacy Policy and be aware of our current terms, Jukin Media’s rights and your
        responsibilities and duties in connection with protecting any information you wish to keep
        private or confidential. Unless otherwise specified, any changes shall be effective when they are
        posted, and your continued use of the Site following the posting of changes will mean that you
        accept and agree to the changes.</p>
        <strong>Effective November 6, 2019</strong>
      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default PrivacyPolicy